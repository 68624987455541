import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import config from '../config';

import styles from './PreviewCompatibleImage.module.scss';

const PreviewCompatibleImage = ({ forceImg, className, imageInfo, alt, style, ...props }) => {
  const { alt: altIntern = alt, childImageSharp, image = {} } = imageInfo;

  if (!forceImg && config.isIE) {
    const src = childImageSharp.fluid.src || image.childImageSharp.fluid.src || image.src || image;
    style = { ...style, backgroundImage: `url(${src})` };
    className = `${className} ${styles.img}`;
    return <div style={style} className={className} alt={altIntern} {...props} />;
  }

  if (image.childImageSharp) {
    return <Img style={style} className={className} fluid={image.childImageSharp.fluid} alt={altIntern} {...props} />;
  }

  if (childImageSharp) {
    return <Img style={style} className={className} fluid={childImageSharp.fluid} alt={altIntern} {...props} />;
  }

  if (typeof image === 'string') return <img style={style} className={className} src={image} alt={altIntern} {...props} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  className: PropTypes.string.isRequired,
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }).isRequired,
};

PreviewCompatibleImage.defaultProps = {
  className: '',
};

export default PreviewCompatibleImage;
