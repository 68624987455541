import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';

import './index.scss';

import styles from './Layout.module.scss';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import config from '../config';

class Layout extends React.Component {
  componentDidMount = () => {
    const { pathname } = this.props;
    const { production, ga } = config;

    if (production) {
      // disable console.log
      console.log = () => {};
      // disable console.info
      console.info = () => {};
      if (ga) {
        ReactGA.initialize(ga);
        ReactGA.set({ page: pathname, anonymizeIp: true });
        ReactGA.pageview(pathname);
      }
    }
  };

  render() {
    const { children, pathname, meta, className, state } = this.props;

    const { title, description } = meta;

    return (
      <div className={className}>
        <Helmet>
          <html lang="de" />
          <title>{title}</title>
          <meta name="description" content={description} />

          <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={pathname} />
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <Navbar pathname={pathname} state={state} />
        <div className={styles.childWrapper}>{children}</div>
        <Footer pathname={pathname} />
      </div>
    );
  }
}

Layout.propTypes = {
  className: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  state: PropTypes.shape({
    internal: PropTypes.bool,
    scrollTo: PropTypes.string,
  }),
  children: PropTypes.node,
};

Layout.defaultProps = {
  className: '',
  state: {},
};

export default Layout;
