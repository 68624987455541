import React from 'react';
import PropTypes from 'prop-types';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Button from './Button';

import styles from './Section.module.scss';

const Section = ({ image, body, title, button, odd, inverted, indent }) => {
  let rootClass = `${styles.root} ${odd ? styles.odd : ''} ${inverted ? styles.inverted : ''} ${indent ? styles.indent : ''}`;
  return (
    <section className={`section ${rootClass}`}>
      <div className={`container ${styles.wrapper}`}>
        <div>
          {title ? <h2 className="title">{title}</h2> : null}
          <div className="content" dangerouslySetInnerHTML={{ __html: body }} />
          {button ? (
            <span>
              <Button to={button.url}>{button.text}</Button>
            </span>
          ) : null}
        </div>
        {image ? <PreviewCompatibleImage alt="" className={styles.img} imageInfo={image} /> : null}
      </div>
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.object,
  inverted: PropTypes.bool,
  indent: PropTypes.bool,
  odd: PropTypes.bool,
  button: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default Section;
