import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './Button.module.scss';

const Button = ({ to, children, inverted }) => {
  const buttonClass = inverted ? `${styles.button} ${styles.inverted}` : styles.button;
  return (
    <Link state={{ internal: true }} className={buttonClass} to={to}>
      {children}
    </Link>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Button;
