import React from 'react';

import styles from './Footer.module.scss';
import Button from './Button';

const Footer = () => {
  return (
    <footer className={styles.root}>
      <span>
        <Button inverted to="/contact">
          Sag hallo
        </Button>
      </span>
      <div>
        <a href="https://www.linkedin.com/in/carla-emrich/" target="__blank">
          <i className="fab fa-linkedin" />
        </a>
        <a href="https://www.instagram.com/change.minds/" target="__blank">
          <i className="fab fa-instagram" />
        </a>
        <a href="https://www.pinterest.de/carlaemrich5/" target="__blank">
          <i className="fab fa-pinterest-square" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
