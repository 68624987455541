const env = process.env.BUILD_ENV || process.env.NODE_ENV || 'development';
const production = env === 'production';

const isIE = () => {
  const ua = typeof window !== 'undefined' ? window.navigator.userAgent : ''; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11
  return msie > 0 || trident > 0;
};

module.exports = {
  ga: 'UA-135107573-3',
  isIE: isIE(),
  env,
  production,
};
