import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Link, StaticQuery, graphql } from 'gatsby';

import styles from './Navbar.module.scss';

import logo from '../../static/logo.svg';

const Navbar = class extends React.Component {
  state = {
    isVisible: false,
    loader: !this.props.state || !this.props.state.internal,
  };

  onClick = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  componentDidMount = () => {
    this.timer = this.state.loader && setTimeout(() => this.setState({ loader: false }), 1500);
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
    window.removeEventListener('scroll', this.handleScroll);
  };

  renderLinks = (array, pathname) => {
    return array
      .sort((a, b) => a.order - b.order)
      .map(({ path, name }) => {
        return (
          <Link partiallyActive={pathname === path} key={path} to={path}>
            {name}
          </Link>
        );
      });
  };

  render() {
    const { pathname } = this.props;
    const { isVisible, loader } = this.state;
    const mobileVisibleClass = isVisible ? styles.mobileVisible : '';
    const loaderClass = loader ? styles.loader : '';
    return (
      <StaticQuery
        query={graphql`
          query navbarMeta {
            allMarkdownRemark(
              sort: { fields: frontmatter___config___order }
              filter: { frontmatter: { templateKey: { glob: "*-page" }, config: { name: { ne: null }, showInNavbar: { eq: true } } } }
            ) {
              edges {
                node {
                  frontmatter {
                    config {
                      path
                      name
                    }
                  }
                }
              }
            }
            #markdownRemark(frontmatter: { templateKey: { eq: "meta" } }) {
            #  frontmatter {
            #    company {
            #      name
            #      body
            #    }
            #  }
            #}
          }
        `}
        render={({ allMarkdownRemark }) => {
          const routes = allMarkdownRemark.edges.map(({ node }) => node.frontmatter.config);
          // const { name, body } = markdownRemark.frontmatter.company;
          return (
            <nav className={`${styles.root} ${loaderClass} ${mobileVisibleClass}`} onClick={this.onClick} role="navigation" aria-label="main-navigation">
              <SVG className={styles.svg} wrapper={React.createFactory('div')} src={logo} uniquifyIDs={false} />
              {!isVisible ? <p>Menü</p> : null}
              <div className={styles.mobileNav} onClick={this.onClick}>
                <div className={styles.links}>{this.renderLinks(routes, pathname)}</div>
                {/* <div className={styles.footer}>
                  <h1 className="title">{name}</h1>
                  <p className="content" dangerouslySetInnerHTML={{ __html: body }} />
                </div> */}
              </div>
            </nav>
          );
        }}
      />
    );
  }
};

Navbar.propTypes = {
  pathname: PropTypes.string.isRequired,
  state: PropTypes.shape({
    internal: PropTypes.bool,
  }),
};

Navbar.defaultProps = {
  className: '',
  state: {},
};

export default Navbar;
